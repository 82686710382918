import {
  BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BSidebar,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, max,
} from '@validations'
import vSelect from 'vue-select'
import { courseSetting } from '@/api/course-setting'
import { showResToast } from '@/@core/utils/utils'

export default {
  name: 'DetailTee',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    listHole: {
      type: Array,
      required: true,
    },
    listCourse: {
      type: Array,
      required: true,
    },
    teeOptions: {
      type: Array,
      required: true,
    },
    listTee: {
      type: Array,
      required: true,
    },
    teeId: {
      type: Number,
      required: true,
    },
    courseSelected: {
      type: Number,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      detailTee: {
        Tee: {
          TeeName: null,
          HoleStartID: null,
          TeeLink: [],
        },
      },
    }
  },
  created() {
    // Dịch vee validate
    if (this.currentLang == 1000000) {
      localize('vi')
    } else {
      localize('en')
    }
    this.getTeeDetail_RTE01_AC2(this.teeId)
  },
  watch: {
    teeId(value) {
      this.getTeeDetail_RTE01_AC2(value)
    },
  },
  methods: {
    afterUpdateDetailTee(res) {
      this.$emit('afterUpdateDetailTee', res)
    },
    afterDeleteTee(res) {
      this.$emit('afterDeleteTee', res)
    },
    afterUpdateStatusTee(res) {
      this.$emit('afterUpdateStatusTee', res)
    },
    // Thông tin chi tiết Tee
    async getTeeDetail_RTE01_AC2(teeId) {
      const body = {
        Tee: {
          Id: teeId,
        },
      }
      await courseSetting
        .getTeeDetail(body)
        .then(res => {
          if (res.Status == '200') {
            this.detailTee = res.Data
          }
        })
    },
    // Cập nhật chi tiết tee
    updateTee_RTE01_AC4() {
      this.$refs.detailTeeForm.validate().then(success => {
        if (success) {
          let teeLink = this.detailTee.Tee.TeeLink.map(x => ({
            ReferenceCourseId: x.ReferenceCourseId,
            ReferenceTeeId: x.ReferenceTeeId,
            CourseId: x.CourseId
          }))

          // Bỏ các phần tử rỗng của mảng teelink
          teeLink = teeLink.filter(x => x.ReferenceCourseId && x.ReferenceTeeId)
          const body = {
            Tee: {
              Id: this.teeId,
              TeeName: this.detailTee.Tee.TeeName,
              HoleStartId: this.detailTee.Tee.HoleStartId,
              TeeLink: teeLink,
            },
          }

          courseSetting
            .updateTee(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == '200') {
                this.afterUpdateDetailTee()
              }
            })
        }
      })
    },
    // Hoạt động lại tee
    async activeTee_RTE01_AC7(teeId) {
      const body = {
        Tee: {
          Id: teeId,
        },
      }
      await courseSetting
        .activeTee(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == '200') {
            this.getTeeDetail_RTE01_AC2(teeId)
            this.afterUpdateDetailTee(res)
          }
        })
    },
    // Ngừng hoạt động tee
    async inActiveTee_RTE01_A5(teeId) {
      const body = {
        Tee: {
          Id: teeId,
        },
      }
      await courseSetting
        .inActiveTee(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getTeeDetail_RTE01_AC2(teeId)
            this.afterUpdateDetailTee(res)
          }
        })
    },
    // Xoá tee
    async deleteTee_RTE01_A6(teeId) {
      const body = {
        Tee: {
          Id: teeId,
        },
      }
      await courseSetting
        .deleteTee(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.teeId = null
            this.afterDeleteTee(res)
          }
        })
    },
    addTeeLink() {
      this.detailTee.Tee.TeeLink.push(
        {
          ReferenceTeeId: null,
          ReferenceCourseId: null,
          CourseId: null,
        },
      )
    },
    deleteTeeLink(index) {
      this.detailTee.Tee.TeeLink.splice(index, 1)
    },
    showResToast,
  },
}
