import {
  BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BSidebar, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, max, alpha_num,
} from '@validations'
import vSelect from 'vue-select'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import { courseSetting } from '@/api/course-setting'
import { showResToast } from '@/@core/utils/utils'

export default {
  name: 'DetailCourse',
  components: {
    VSwatches,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    listHotel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      isOpen: false,
      listNumberOfHoles: [
        {
          value: 9,
          title: `9 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 18,
          title: `18 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 27,
          title: `27 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 36,
          title: `36 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 45,
          title: `45 ${this.$t('golf_common_hole')}`,
        },
        {
          value: 54,
          title: `54 ${this.$t('golf_common_hole')}`,
        },
      ],
      detailCourse: {
        Color: '#0D6EFD',
      },
      courseId: null,
      listColor: ['#0D6EFD', '#3D10F2', '#7367F0', '#D63384', '#EA5455', '#FD7E14', '#FFD643', '#28C741', '#20C9B5', '#00CFE8'],
    }
  },
  methods: {
    open(courseId) {
      // Dịch vee validate
      if (this.currentLang == 1000000) {
        localize('vi')
      } else {
        localize('en')
      }
      this.$refs.courseForm.reset()

      this.isOpen = true
      this.courseId = courseId
      if (this.courseId) {
        this.getDetailCourse_RCO01_AC4(this.courseId)
      } else {
        this.detailCourse = {
          NumberOfHoles: 9,
          InternalCustomerManagement: 'BAGTAG',
          Color: '#0D6EFD',
        }
      }
    },
    afterUpdateStatusCourse(res) {
      this.$emit('afterUpdateStatusCourse', res)
    },
    afterUpdateDetailCourse(res) {
      this.$emit('afterUpdateDetailCourse', res)
    },
    // Xem chi tiết 1 sân
    async getDetailCourse_RCO01_AC4(courseID) {
      const body = {
        Course: {
          Id: courseID,
        },
      }
      await courseSetting
        .getCourseDetail(body)
        .then(res => {
          if (res) {
            if (res.Status == '200') {
              this.detailCourse = res.Data.Course
            }
          }
        })
    },
    // Thêm mới sân
    addNewCourse_ICO01_AC2() {
      this.$refs.courseForm.validate().then(success => {
        if (success) {
          const body = {
            Course: {
              CourseCode: this.detailCourse.CourseCode,
              CourseName: this.detailCourse.CourseName,
              Color: this.detailCourse.Color,
              NumberOfHoles: this.detailCourse.NumberOfHoles,
              InternalCustomerManagement: this.detailCourse.InternalCustomerManagement,
              HotelId: this.detailCourse.HotelId,
            },
          }
          courseSetting
            .addCourse(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.isOpen = false
                this.afterUpdateDetailCourse(res)
              }
            })
        }
      })
    },
    // Cập nhật thông tin sân
    updateDetailCourse_UCO01_AC3() {
      this.$refs.courseForm.validate().then(success => {
        if (success) {
          const body = {
            Course: {
              Id: this.courseId,
              CourseCode: this.detailCourse.CourseCode,
              CourseName: this.detailCourse.CourseName,
              Color: this.detailCourse.Color,
              NumberOfHoles: this.detailCourse.NumberOfHoles,
              InternalCustomerManagement: this.detailCourse.InternalCustomerManagement,
              HotelId: this.detailCourse.HotelId,
            },
          }
          courseSetting
            .updateCourseDetail(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.isOpen = false
                this.afterUpdateDetailCourse(res)
              }
            })
        }
      })
    },
    // Hoạt động lại sân
    activeCourse_UCO01_AC7(courseId) {
      const body = {
        Course: {
          Id: courseId,
        },
      }
      courseSetting
        .activeCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.isOpen = false
            this.afterUpdateStatusCourse(res)
          }
        })
    },
    // Ngừng hoạt động lại sân
    inActiveCourse_UCO01_AC6(courseId) {
      const body = {
        Course: {
          Id: courseId,
        },
      }
      courseSetting
        .inActiveCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.isOpen = false
            this.afterUpdateStatusCourse(res)
          }
        })
    },
    // Xoá sân
    deleteCourse_DCO01_AC7(courseId) {
      const body = {
        Course: {
          Id: courseId,
        },
      }
      courseSetting
        .deleteCourse(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.isOpen = false
            this.afterUpdateStatusCourse(res)
          }
        })
    },
    showResToast,
  },
}
