/* eslint-disable no-unreachable */
import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BSidebar, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { courseSetting } from '@/api/course-setting'

import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import DetailCourse from './DetailCourse/DetailCourse.vue'
import AddNewTee from './AddNewTee/AddNewTee.vue'
import DetailTee from './DetailTee/DetailTee.vue'

export default {
  name: 'CourseSetting',
  components: {
    AddNewTee,
    DetailTee,
    DetailCourse,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BSidebar,
    BCardCode,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    'delete-dialog': DeleteDialog,
  },
  data() {
    return {
      listCourse: [],
      listHotel: [],
      listOptionTee: [],
      listTee: [],
      courseSelected: null,
      teeSelected: null,
      listHole: [],
      teeOptions: [],
      RemoveData: null,
      currentLang: this.$i18n.locale,
      isLoadingCourse: false,
    }
  },
  computed: {
    cours() {
      return this.listCourse
    }
  },
  created() {
    this.getListCourse_RCO01_AC1()
  },
  methods: {
    // Lấy tất cả tee của sử dụng cho option TeeLink
    async getListCourseAndTee() {
      // Lấy danh sách sân
      await courseSetting
        .getListCourse()
        .then(res => {
          if (res) {
            this.listCourse = res.Data.Course
            this.listCourse.forEach(course => {
              const body = {
                Course: {
                  Id: course.Id,
                },
              }
              // Lấy danh sách tee của sân
              courseSetting
                .getListTee(body)
                .then(res => {
                  if (res.Status == '200') {
                    this.teeOptions.push({
                      courseId: course.Id,
                      tee: res.Data.Tee,
                    })
                  }
                })
            })
          }
        })
    },
    async getListCourse_RCO01_AC1() {
      this.isLoadingCourse = true
      // Lấy danh sách course và tee
      await this.getListCourseAndTee()
      // Lấy danh sách địa điểm
      await courseSetting
        .getListHotel()
        .then(res => {
          if (res) {
            this.listHotel = res.Data.Hotel
          }
        })
      this.isLoadingCourse = false
    },
    async getListTee_RTE01_AC1(courseID, isUpdateTee) {
      // Nếu chọn sân hiện tại thì không call lại api
      // if (this.courseSelected === courseID && isForce) { return }

      // Set sân đang xem chi tiết
      this.courseSelected = courseID
      // Chọn lại sân thì set lại teeSelected
      if (!isUpdateTee) {
        this.teeSelected = null
      }

      // Lấy danh sách tee
      const body = {
        Course: {
          Id: courseID,
        },
      }
      // Lấy danh sách hole của sân hiện tại
      await courseSetting
        .getListHole(body)
        .then(res => {
          if (res) {
            this.listHole = res.Data.Hole
          }
        })

      await courseSetting
        .getListTee(body)
        .then(res => {
          if (res) {
            this.listTee = res.Data.Tee
          }
        })
    },
    afterAddNewTee() {
      // Load lại list Tee
      this.getListCourseAndTee()
      // Load lại thông tin tee của sân hiện tại
      this.getListTee_RTE01_AC1(this.courseSelected)
    },
    deleteTee_DTE01_A6(data) {
      const boby = {
        Tee: {
          Id: data.Id,
          IsForce: !!this.RemoveData,
        },
      }
      courseSetting
        .deleteTee(boby)
        .then(res => {
          if (res) {
            if (res.Status === '200') {
              this.RemoveData = null
              this.showResToast(res)
              this.getListTee_RTE01_AC1(this.courseSelected)
            } else {
              this.RemoveData = res.Messages[0].MessageText
              setTimeout(e => {
                this.$refs.confirmDeleteDialogComponent.open({
                  Id: data.Id,
                })
              }, 100)
            }
          }
        })
    },
  },
}
