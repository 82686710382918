import {
  BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BSidebar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, length, max, integer, double, email,
} from '@validations'
import vSelect from 'vue-select'
import { courseSetting } from '@/api/course-setting'
import { showResToast } from '@/@core/utils/utils'

export default {
  name: 'AddNewTee',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    listHole: {
      type: Array,
      required: true,
    },
    listCourse: {
      type: Array,
      required: true,
    },
    teeOptions: {
      type: Array,
      required: true,
    },

  },
  data() {
    return {
      isOpen: false,
      addNewTee: {
        Tee: {
          TeeName: null,
          HoleStartID: null,
          TeeLink: [],
        },
      },
      courseSelected: null,
      currentLang: this.$i18n.locale,
    }
  },
  methods: {
    open(courseSelected) {
      // Dịch vee validate
      if (this.currentLang == 1000000) {
        localize('vi')
      } else {
        localize('en')
      }
      this.$refs.addNewTeeForm.reset()

      this.isOpen = true
      this.courseSelected = courseSelected

      this.addNewTee = {
        Tee: {
          TeeName: null,
          HoleStartID: null,
          TeeLink: [],
        },
      }
    },
    afterAddNewTee(res) {
      this.$emit('afterAddNewTee', res)
    },
    // Thêm tee mới
    addNewTee_RTE01_AC3() {
      this.$refs.addNewTeeForm.validate().then(success => {
        if (success) {
          this.addNewTee.Tee.CourseID = this.courseSelected
          this.addNewTee.Tee.TeeLink = this.addNewTee.Tee.TeeLink.filter(x => x.ReferenceCourseId && x.ReferenceTeeId)
          courseSetting
            .addNewTee(this.addNewTee)
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.isOpen = false
                this.afterAddNewTee(res)
              }
            })
        }
      })
    },
    addTeeLink() {
      this.addNewTee.Tee.TeeLink.push(
        {
          ReferenceTeeId: '',
          ReferenceCourseId: '',
        },
      )
    },
    deleteTeeLink(index) {
      this.addNewTee.Tee.TeeLink.splice(index, 1)
    },
    showResToast,
  },
}
